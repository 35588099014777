/*eslint func-names: ["error", "never"]*/
import axios from 'axios';
import store from '@/core/store/PersistentStorage';
import eventBus from "@/core/services/eventBus";

const assignmentApiClient: any = axios.create({
    baseURL: (process.env.VUE_APP_SERVERLESS == true) ? '/mock_api' : (process.env.VUE_APP_ASSIGNMENTS_API)
});

assignmentApiClient.allowedCodes = [];

assignmentApiClient.token = null;

if (process.env.VUE_APP_SERVERLESS == true) {
    assignmentApiClient.post = function (a) {
        return assignmentApiClient.get(a);
    };
}

/* When chained into a request, this function allows a specific HTTP response code, or array of codes, without throwing the "A system error occurred" notification in the UI.
   Use like this: apiClient.allow([422, 421]).post(...)

   This is useful if you want specific server errors to be handled within your component, such as for form validation.
 */
assignmentApiClient.allow = function (errorCodes) {
    if (Array.isArray(errorCodes)) {
        assignmentApiClient.allowedCodes = errorCodes;
    } else {
        assignmentApiClient.allowedCodes = [errorCodes];
    }
    return this;
};

assignmentApiClient.download = function (url) {
    return assignmentApiClient({url, method: 'GET', responseType: 'blob'});
};

assignmentApiClient.create = function (endpoint, data) {
    return this.post(endpoint, data);
};

assignmentApiClient.update = function (endpoint, data) {
    return this.put(endpoint + '/' + data.id, data);
};

assignmentApiClient.remove = function (endpoint, data) {
    return this.delete(endpoint + '/' + data.id);
};

assignmentApiClient.interceptors.request.use(request => {
    if (store.authentication.accessToken) {
        request.headers.Authorization = `Bearer ${store.authentication.accessToken}`;
    }
    return request;
});

assignmentApiClient.interceptors.response.use(response => {
        return response;
    }, async error => {
        if (error.request.status == 401) {
            eventBus.$emit('logout');
        } else {
            // If error was not 401 just reject as is
            throw error;
        }
    }
);

export default assignmentApiClient;
