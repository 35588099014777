
import {defineComponent, nextTick} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import eventBus from "@/core/services/eventBus";
import HelpModal from "@/app/components/modals/HelpModal.vue";
import moment from 'moment-with-locales-es6';

import { useRoute } from 'vue-router';

import store from '@/core/store/PersistentStorage';
import assignmentApiClient from '@/app/services/assignmentApiClient';

export default defineComponent({
  name: "assignment-unit",
  components: { HelpModal },
  beforeUnmount() {
    eventBus.$off('openHelp');
  },
  data() {
    return {
      assignments: undefined,
      assignmentsStock: undefined,
      interactiveDocumentsSSOLink: 
        process.env.VUE_APP_ARCID_HOST +
        '/authorize?response_type=code&client_id=' +
        process.env.VUE_APP_INTERACTIVE_DOCUMENTS_OAUTH_CLIENT_ID +
        '&redirect_uri=' +
        process.env.VUE_APP_INTERACTIVE_DOCUMENTS_URI +
        '/oauth/arcid',
      interactiveDocumentsUri: process.env.VUE_APP_INTERACTIVE_DOCUMENTS_URI,
      periods: [],
      unit: undefined
    }
  },
  computed: {
    thisWeeksDateRange() {
      const thisWeeksPeriod = this.periods.find((period) => period.id === 1)
      const moments = thisWeeksPeriod.assignments.map(assignment => moment(assignment.due_date).local())
      const startDate = moment.min(moments)
      const endDate = moment.max(moments)
      const formattedStart = moment(startDate).format('ddd. MM/DD/YY')
      const formattedEnd = moment(endDate).format('ddd. MM/DD/YY')
      return formattedStart === formattedEnd ? formattedStart : formattedStart + ' - ' + formattedEnd
    },
    todaysDateOutput() {
      return moment().format('ddd. M/D/YY')
    }
  },
  methods: {
    findAssignments($event) {
      if ($event.target.value !== '') {
        const searchString = $event.target.value.toLowerCase()
        this.assignments = this.assignmentsStock.filter((assignment) => {
          return assignment.assignment_template.name.toLowerCase().includes(searchString) || assignment.assignment_template.instructions.toLowerCase().includes(searchString)
        })
        if (this.assignments.length) {
          // Expand all accordions if there are filtered results
          if (this.$refs.accordionButton) {
            this.$refs.accordionButton.forEach((button) => {
              button.classList.remove('collapsed')
            })
          }
          if (this.$refs.accordionCollapse) {
            this.$refs.accordionCollapse.forEach((segment) => {
              segment.classList.remove('collapse')
            })
          }
        }
      }
      else {
        this.assignments = this.assignmentsStock
        // Collapse accordions
        if (this.$refs.accordionButton) {
          this.$refs.accordionButton.forEach((button) => {
            if (!button.classList.contains('today')) button.classList.add('collapsed')
          })
        }
        if (this.$refs.accordionCollapse) {
          this.$refs.accordionCollapse.forEach((segment) => {
            if (!segment.classList.contains('today')) segment.classList.add('collapse')
          })
        }
      }
      this.partitionAssignments()
    },
    getSubmittedTime(submitted) {
      return moment.utc(submitted).local().format('M/D [at] hh:mmA')
    },
    isLocked(unlockDate) {
      const local = moment(unlockDate).local().format()
      return moment().isBefore(local)
    },
    openAssignment(assignment, e) {
      e.preventDefault()
      let interactiveDocumentURL
      if (assignment.student_assignments[0]?.id) {
        // Assignment has been started
        interactiveDocumentURL = this.interactiveDocumentsSSOLink +
          '&state=' +
          this.interactiveDocumentsUri +
          '%2F' +
          assignment.student_assignments[0].id
          window.open(interactiveDocumentURL, '_blank')?.focus()
      }
      else {
        // Assignment has not been started yet
        const payload = {
          studentInstanceId: store.authentication.user.currentInstance.studentInstanceId,
          classroomAssignmentId: assignment.id
        }
        assignmentApiClient.post(`/studentAssignments`, payload).then(response => {
          if (response.data.id) {
            // Push new assignment object with its id to student_assignments of this assignment
            assignment.student_assignments.push(response.data)
            interactiveDocumentURL = this.interactiveDocumentsSSOLink +
              '&state=' +
              this.interactiveDocumentsUri +
              '%2F' +
              response.data.id
            window.open(interactiveDocumentURL, '_blank')?.focus()
          }
        });
      }
    },
    partitionAssignments() {
      this.periods.length = 0
      this.periods.push(
        {
          "id": -2
        }
      )
      this.periods.push(
        {
          "id": -1
        }
      )
      this.periods.push(
        {
          "id": 0,
        }
      )
      this.periods.push({
        "id": 1,
        "firstDay": null,
        "lastDay": null
      })
      this.periods.push({
        "id": 2
      })
      this.periods.push({
        "id": 3
      })
      // Place assignments in respective periods
      this.periods.forEach((period) => {
        switch (period.id) {
          // Past Due
          case -2:
            period.assignments = this.assignments.filter((assignment) => {
              const local = moment(assignment.due_date).local().format()
              return moment().isAfter(local) && !moment().isSame(local, 'day') && !assignment.student_assignments?.find((studentAssignment) => { return studentAssignment.status_id >= 3 })
            })
            break
          // Needs Attention
          case -1:
            period.assignments = this.assignments.filter((assignment) => {
              return assignment.student_assignments?.find((studentAssignment) => { return studentAssignment.status_id === 5 })
            })
            // Retrieve comments for each assignment
            if (period.assignments.length) {
              for (const assignment of period.assignments) {
                // Find first student assignment
                const studentAssignment = assignment.student_assignments?.find((studentAssignment) => { return studentAssignment.status_id === 5 })
                assignment.feedback = null
                if (studentAssignment.id) {
                  assignmentApiClient.get(`/studentAssignments/${studentAssignment.id}`).then(response => {
                    if (response.status === 200) {
                      if (response.data.student_assignment_comments && response.data.student_assignment_comments.length) {
                        assignment.feedback = response.data.student_assignment_comments[0]
                      }
                    }
                  });
                }
              }
            }
            break
          // Due Today
          case 0:
            period.assignments = this.assignments.filter((assignment) => {
              const local = moment(assignment.due_date).local().format()
              return moment().isSame(local, 'day') && !assignment.student_assignments?.find((studentAssignment) => { return studentAssignment.status_id >= 5 })
            })
            break
          // Due this Week
          case 1:
            period.assignments = this.assignments.filter((assignment) => {
              const local = moment(assignment.due_date).local().format()
              return !moment().isAfter(local) && moment().isSame(local, 'week') && !moment().isSame(local, 'day') && !assignment.student_assignments.find((studentAssignment) => { return studentAssignment.status_id === 4 })
            })
            break
          // Upcoming Assignments
          case 2:
            period.assignments = this.assignments.filter((assignment) => {
              const local = moment(assignment.due_date).local().format()
              return !moment().isAfter(local) && !moment().isSame(local, 'day') && !moment().isSame(local, 'week') && !assignment.student_assignments.find((studentAssignment) => { return studentAssignment.status_id >= 3 })
            })
            break
          // Completed Assignments
          case 3:
            period.assignments = this.assignments.filter((assignment) => {
              const local = moment(assignment.due_date).local().format()
              return !moment().isSame(local, 'day') && assignment.student_assignments.find((studentAssignment) => { return studentAssignment.status_id === 4 || studentAssignment.status_id === 3 })
            })
            break
        }
      })
    },
    formatDeadline(timestamp) {
      const local = moment(timestamp).local().format()
      if (moment().isSame(local, 'day')) {
        return 'Today'
      }
      else {
        return moment(local).calendar(null,{
          lastDay : '[Yesterday]',
          sameDay : '[Today]',
          nextDay : '[Tomorrow]',
          lastWeek : '[Last] dddd',
          nextWeek : 'dddd',
          sameElse : 'L'
        })
      }
    },
    formatFeedbackTime(timestamp) {
      return moment(timestamp).local().calendar()
    },
    formatPeriodSpan(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      const formattedStart = moment(startDate).format('ddd. MM/DD/YY')
      const formattedEnd = moment(endDate).format('ddd. MM/DD/YY')
      return formattedStart + ' - ' + formattedEnd
    },
    isCurrentWeek(timestamp) {
      const local = moment(timestamp).local().format()
      return moment().isSame(local, 'week')
    },
    isDeadlineImpending(timestamp) {
      // Set today's date to tomorrow endOf('day') and see if the deadline isAfter it
      const local = moment(timestamp).local().format()
      const tomorrowEndOfDay = moment().add(1, 'days').endOf('day')
      if (tomorrowEndOfDay.isAfter(local)) return true
      return false
    },
    sortAssignments(arr) {
      arr.sort((a, b) => {
        const dateComparison = new Date(a.due_date).valueOf() - new Date(b.due_date).valueOf()
        if (dateComparison !== 0) {
          return dateComparison
        }
        return a.assignment_template.sort_order - b.assignment_template.sort_order
      });
      return arr
    },
    updateAssignment(studentAssignment) {
      // Find Assignment to update
      for (const assignment of this.assignmentsStock) {
        let studentAssignmentIndex = assignment.student_assignments?.findIndex((student_assignment) => student_assignment.id === studentAssignment.id)
        if (studentAssignmentIndex !== -1) {
          const assignmentsStockIndex = this.assignmentsStock.findIndex((assignment) => {
            return assignment.student_assignments?.find((student_assignment) => student_assignment.id === studentAssignment.id)
          })
          if (assignmentsStockIndex !== -1) {
            this.assignmentsStock[assignmentsStockIndex].student_assignments[studentAssignmentIndex] = studentAssignment
          }
          break
        }
      }
      for (const assignment of this.assignments) {
        let studentAssignmentIndex = assignment.student_assignments?.findIndex((student_assignment) => student_assignment.id === studentAssignment.id)
        if (studentAssignmentIndex !== -1) {
          const assignmentsIndex = this.assignments.findIndex((assignment) => {
            return assignment.student_assignments?.find((student_assignment) => student_assignment.id === studentAssignment.id)
          })
          if (assignmentsIndex !== -1) {
            this.assignments[assignmentsIndex].student_assignments[studentAssignmentIndex] = studentAssignment
          }
          break
        }
      }
      this.partitionAssignments()
    },
    updateAssignmentStatus(assignment, $event: Event) {
      const done = ($event.target as HTMLInputElement).checked
      if (!assignment.student_assignments.length) {
        // Assignment has not been started yet
        const payload = {
          studentInstanceId: store.authentication.user.currentInstance.studentInstanceId,
          classroomAssignmentId: assignment.id
        }
        assignmentApiClient.post(`/studentAssignments`, payload).then(response => {
          if (response.data.id) {
            assignment.student_assignments.push(response.data)
            const patchPayload = {
              "status_id": 4
            }
            assignmentApiClient.patch(`/studentAssignments/${response.data.id}`, patchPayload).then(response => {
              if (response.data) {
                this.updateAssignment(response.data.studentAssignment)
              }
            })
          }
        });
      }
      else {
        let status
        if (done) {
          status = 4
        }
        else {
          status = 2
        }
        const patchPayload = {
          "status_id": status
        }
        assignmentApiClient.patch(`/studentAssignments/${assignment.student_assignments[0]?.id}`, patchPayload).then(response => {
          if (response.data) {
            this.updateAssignment(response.data.studentAssignment)
          }
        })
      }
    }
  },
  mounted() {
    setCurrentPageTitle("My Assignments");
    eventBus.$on('openHelp', () => {
      this.$refs.helpModal.openDialog();
    });
    window.addEventListener('message', (event) => {
      if (event.origin !== process.env.VUE_APP_INTERACTIVE_DOCUMENTS_URI) return
      if (event.data) {
        // Find Assignment to update
        this.updateAssignment(event.data.studentAssignment)
      }
    }, false);
  },
  created() {
    const route = useRoute()
    if (route.params.id) {
      assignmentApiClient.get(`fusionUnits/${route.params.id}`).then(response => {
        this.unit = response.data
      });
    }

    assignmentApiClient.get(`/students/${store.authentication.user.currentInstance.studentInstanceId}/assignments`).then(response => {
      if (response.data.length) {
        this.assignments = this.sortAssignments(response.data)
        this.assignmentsStock = this.assignments
        this.partitionAssignments()
        // Jump links for assignments
        if (window.location.hash) {
          const assignmentHash = window.location.hash
          nextTick().then(() => {
            const assignment = document.querySelector(assignmentHash);
            if (assignment) {
              assignment.classList.add('active');
              const accordionParent = assignment.closest('.accordion-collapse');
              if (accordionParent) {
                accordionParent.classList.add('show');
                assignment.scrollIntoView();
                accordionParent.previousElementSibling?.querySelector('.accordion-button')?.classList.remove('collapsed');
              }
              
            }
          })
          
        }
      }
      else {
        this.assignments = null
      }
    });
  }
});
